exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-comparador-index-tsx": () => import("./../../../src/pages/comparador/index.tsx" /* webpackChunkName: "component---src-pages-comparador-index-tsx" */),
  "component---src-pages-conectividade-allcom-multi-tsx": () => import("./../../../src/pages/conectividade/allcom-multi.tsx" /* webpackChunkName: "component---src-pages-conectividade-allcom-multi-tsx" */),
  "component---src-pages-conectividade-comunicacao-satelital-tsx": () => import("./../../../src/pages/conectividade/comunicacao-satelital.tsx" /* webpackChunkName: "component---src-pages-conectividade-comunicacao-satelital-tsx" */),
  "component---src-pages-conectividade-lora-tsx": () => import("./../../../src/pages/conectividade/lora.tsx" /* webpackChunkName: "component---src-pages-conectividade-lora-tsx" */),
  "component---src-pages-conectividade-redes-privativas-tsx": () => import("./../../../src/pages/conectividade/redes-privativas.tsx" /* webpackChunkName: "component---src-pages-conectividade-redes-privativas-tsx" */),
  "component---src-pages-conectividade-tsx": () => import("./../../../src/pages/conectividade.tsx" /* webpackChunkName: "component---src-pages-conectividade-tsx" */),
  "component---src-pages-etica-tsx": () => import("./../../../src/pages/etica.tsx" /* webpackChunkName: "component---src-pages-etica-tsx" */),
  "component---src-pages-eventos-tsx": () => import("./../../../src/pages/eventos.tsx" /* webpackChunkName: "component---src-pages-eventos-tsx" */),
  "component---src-pages-fale-conosco-tsx": () => import("./../../../src/pages/fale-conosco.tsx" /* webpackChunkName: "component---src-pages-fale-conosco-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-grupo-allcom-tsx": () => import("./../../../src/pages/grupo-allcom.tsx" /* webpackChunkName: "component---src-pages-grupo-allcom-tsx" */),
  "component---src-pages-hardware-cameras-tsx": () => import("./../../../src/pages/hardware/cameras.tsx" /* webpackChunkName: "component---src-pages-hardware-cameras-tsx" */),
  "component---src-pages-hardware-index-tsx": () => import("./../../../src/pages/hardware/index.tsx" /* webpackChunkName: "component---src-pages-hardware-index-tsx" */),
  "component---src-pages-hardware-pos-tsx": () => import("./../../../src/pages/hardware/pos.tsx" /* webpackChunkName: "component---src-pages-hardware-pos-tsx" */),
  "component---src-pages-hardware-rastreadores-de-ativos-tsx": () => import("./../../../src/pages/hardware/rastreadores-de-ativos.tsx" /* webpackChunkName: "component---src-pages-hardware-rastreadores-de-ativos-tsx" */),
  "component---src-pages-hardware-rastreadores-tag-tsx": () => import("./../../../src/pages/hardware/rastreadores-tag.tsx" /* webpackChunkName: "component---src-pages-hardware-rastreadores-tag-tsx" */),
  "component---src-pages-hardware-rastreadores-tsx": () => import("./../../../src/pages/hardware/rastreadores.tsx" /* webpackChunkName: "component---src-pages-hardware-rastreadores-tsx" */),
  "component---src-pages-hardware-redes-privativas-tsx": () => import("./../../../src/pages/hardware/redes-privativas.tsx" /* webpackChunkName: "component---src-pages-hardware-redes-privativas-tsx" */),
  "component---src-pages-hardware-roteadores-tsx": () => import("./../../../src/pages/hardware/roteadores.tsx" /* webpackChunkName: "component---src-pages-hardware-roteadores-tsx" */),
  "component---src-pages-hardware-telemetria-tsx": () => import("./../../../src/pages/hardware/telemetria.tsx" /* webpackChunkName: "component---src-pages-hardware-telemetria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-midia-tsx": () => import("./../../../src/pages/midia.tsx" /* webpackChunkName: "component---src-pages-midia-tsx" */),
  "component---src-pages-mvno-tsx": () => import("./../../../src/pages/mvno.tsx" /* webpackChunkName: "component---src-pages-mvno-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-software-astro-tsx": () => import("./../../../src/pages/software/astro.tsx" /* webpackChunkName: "component---src-pages-software-astro-tsx" */),
  "component---src-pages-software-index-tsx": () => import("./../../../src/pages/software/index.tsx" /* webpackChunkName: "component---src-pages-software-index-tsx" */),
  "component---src-pages-solucoes-index-tsx": () => import("./../../../src/pages/solucoes/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-index-tsx" */),
  "component---src-pages-solucoes-rastreamento-basico-tsx": () => import("./../../../src/pages/solucoes/rastreamento-basico.tsx" /* webpackChunkName: "component---src-pages-solucoes-rastreamento-basico-tsx" */),
  "component---src-pages-solucoes-telemetria-basica-tsx": () => import("./../../../src/pages/solucoes/telemetria-basica.tsx" /* webpackChunkName: "component---src-pages-solucoes-telemetria-basica-tsx" */),
  "component---src-pages-solucoes-telemetria-gestao-tsx": () => import("./../../../src/pages/solucoes/telemetria-gestao.tsx" /* webpackChunkName: "component---src-pages-solucoes-telemetria-gestao-tsx" */),
  "component---src-pages-solucoes-tsx": () => import("./../../../src/pages/(solucoes).tsx" /* webpackChunkName: "component---src-pages-solucoes-tsx" */),
  "component---src-pages-trabalhe-conosco-tsx": () => import("./../../../src/pages/trabalhe-conosco.tsx" /* webpackChunkName: "component---src-pages-trabalhe-conosco-tsx" */)
}

