import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

import './src/assets/css/fontawesome.min.css'
import './src/assets/css/bootstrap.min.css'
import './src/assets/css/magnific-popup.min.css'
import './src/assets/css/swiper-bundle.min.css'
import './src/assets/css/style.css'
import './src/assets/css/allcom.css'


// Função para fadeOut
function fadeOut(element, duration = 1000) {
    element.style.opacity = 1;
    let start = performance.now();

    function step(timestamp) {
        let progress = (timestamp - start) / duration;
        if (progress < 1) {
            element.style.opacity = 1 - progress;
            requestAnimationFrame(step);
        } else {
            element.style.opacity = 0;
            element.style.display = 'none'; // Garante que o elemento não ocupe espaço
        }
    }

    requestAnimationFrame(step);
}

// Carregar os scripts de forma síncrona
const loadScriptSequentially = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = withPrefix(src);
        script.onload = () => resolve(src);
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
    });
};

// Função para carregar todos os scripts
const loadAllScripts = async () => {
    const scripts = [
        '/js/vendor/jquery-3.7.1.min.js',
        '/js/jquery-ui.min.js',
        '/js/jquery.magnific-popup.min.js',
        '/js/jquery.counterup.min.js',
        '/js/tilt.jquery.min.js',
        '/js/swiper-bundle.min.js',
        '/js/bootstrap.min.js',
        '/js/circle-progress.js',
        '/js/imagesloaded.pkgd.min.js',
        '/js/isotope.pkgd.min.js',
        '/js/gsap.min.js',
        '/js/ScrollTrigger.min.js',
        '/js/smooth-scroll.js',
        '/js/particles.min.js',
        '/js/particles-config.js',
        '/js/main.js',
        '/js/custom-sticky.js',
        // leadster
        'https://cdn.leadster.com.br/neurolead/neurolead.min.js'
    ];

    try {
        for (const script of scripts) {
            await loadScriptSequentially(script);
        }
        console.log('All scripts loaded successfully.');

        // adicionando Neurolead ID extraído do script
        (window as any).neuroleadId = 19880;
    } catch (error) {
        console.error('Error loading scripts:', error);
    }
};

export const wrapRootElement = ({ element }) => {

    // Chama a função de carregamento de scripts diretamente
    if (typeof window !== 'undefined') {
        loadAllScripts();
    }

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>

                {/* Base URL */}
                {/* <base key="base" href="/novo/" /> */}

                {/* Icon */}
                <link rel="shortcut icon" href={withPrefix("/images/icon.png")} type="image/png" />

                {/* Google fonts */}
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&family=Roboto:wght@300;400;500;700;900&display=swap" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.1/css/all.min.css" integrity="sha512-5Hs3dF2AEPkpNAR7UiOHba+lRSJNeM2ECkwxUIxC1Q/FLycGTbNapWXB4tP889k5T5Ju8fs4b1P5z/iB4nMfSQ==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

                {/* Adopt */}
                <meta name="adopt-website-id" content="8172b965-6323-4914-9ae6-a665698fda43" />
                <script src="//tag.goadopt.io/injector.js?website_code=8172b965-6323-4914-9ae6-a665698fda43" className="adopt-injector"></script>

                <script>
                    {`!function (f, b, e, v, n, t, s) {
                        if (f.fbq) return; n = f.fbq = function () {
                                    n.callMethod ?
                                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                                };
                                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                                n.queue = []; t = b.createElement(e); t.async = !0;
                                t.src = v; s = b.getElementsByTagName(e)[0];
                                s.parentNode.insertBefore(t, s)
                    }(window, document, 'script',
                                'https://connect.facebook.net/en_US/fbevents.js');
                                fbq('init', '525754944762788');
                                fbq('track', 'PageView');`}
                </script>
                <noscript>{`<img height="1" width="1" style={{ display: 'none' }}
                    src="https://www.facebook.com/tr?id=525754944762788&ev=PageView&noscript=1" />`}</noscript>

                <script async src="https://www.googletagmanager.com/gtag/js?id=G-YF0MH06Q8S"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag() {dataLayer.push(arguments); }
                    gtag('js', new Date());

                    gtag('config', 'G-YF0MH06Q8S');`}
                </script>

                <script type="text/javascript">
                    {`_linkedin_partner_id = "5762561";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
                </script>

                <script type="text/javascript">
                    {`(function (l) {
                        if (!l) {
                                    window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                                window.lintrk.q = []
                        }
                                var s = document.getElementsByTagName("script")[0];
                                var b = document.createElement("script");
                                b.type = "text/javascript"; b.async = true;
                                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                                s.parentNode.insertBefore(b, s);
                    })(window.lintrk);`}
                </script>
                <noscript>
                    {`
                    <img height="1" width="1" style={{ display: 'none' }} alt=""
                        src="https://px.ads.linkedin.com/collect/?pid=5762561&fmt=gif" />
                    `}
                </noscript>

                <script type="text/javascript" async
                    src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/9ee68917-d8c6-48b1-98a9-839029790814-loader.js"></script>

            </Helmet >
            {element}
        </>
    )
};

export const onPreRouteUpdate = () => {
    setTimeout(() => {
        let obj = document.getElementById("preloader");
        if (obj) fadeOut(obj, 500)
    }, 1000)
}